<template>
  <div class="container">
    <div class="row mt-3">
      <div v-if="realItem" class="col-12 col-md-8 offset-md-2 was-validated">
        <div class="d-flex justify-content-end">
          <router-link
            class="btn btn-sm btn-success mb-3 mr-3"
            :to="{ name: 'addStockChange', params: { itemId: realItem.id } }"
            >Add Change</router-link
          >
        </div>
        <div class="form-group row">
          <label class="col-3">Name</label>
          <generic-input-component :object="realItem" property="name" endpoint="/item/:id" required :minLength="4" />
        </div>
        <div class="form-group row">
          <label class="col-3">Barcode</label>
          <edit-barcode-component :object="realItem" property="barcode"/>
        </div>
        <div class="form-group row">
          <label class="col-3">Barcode of Carton</label>
          <edit-barcode-component :object="realItem" property="barcodePack"/>
        </div>
        <div class="form-group row">
          <label class="col-3">Article Number</label>
          <generic-input-component :object="realItem" property="articleNumber" endpoint="/item/:id" />
        </div>
        <div class="form-group row">
          <label class="col-3">Seller</label>
          <label class="col-9">{{ realItem.seller }}</label>
        </div>
        <div class="form-group row">
          <label class="col-3">Content</label>
          <edit-content-component :object="realItem" />
        </div>
        <div class="form-group row">
          <label class="col-3">Alcohol by volume</label>
          <edit-percent-component :object="realItem" property="alcoholByVolume" endpoint="/item/:id" />
        </div>
        <div class="form-group row">
          <label class="col-3">ItemGroup</label>
          <edit-item-group-component :object="realItem" />
        </div>
        <div class="form-group row">
          <label class="col-3">Image</label>
          <label class="col-9">
            <button
              v-if="realItem.imageId !== null"
              class="ml-2 btn btn-sm btn-sm-flat btn-secondary"
              type="button"
              v-on:click="openImage"
            >
              <font-awesome-icon icon="image" />
            </button>
            <template v-else>None</template>
          </label>
        </div>
        <div class="form-group row">
          <label class="col-3">Position</label>
          <edit-position-component :object="realItem" endpoint="/item/:id" :usedForItem="true" />
        </div>
        <div class="form-group row">
          <label class="col-3">In Stock</label>
          <label class="col-9">{{ stock === undefined ? "Loading..." : stock ? stock.inStock : "0" }}</label>
        </div>
        <div class="form-group row">
          <label class="col-3">Website</label>
          <edit-url-component :object="realItem" property="website" />
        </div>
        <div class="form-group row">
          <label class="col-3">Interal Note</label>
          <edit-textarea-component :object="realItem" property="internalNote" endpoint="/item/:id" />
        </div>
        <div class="row" v-if="stockChanges && stockChanges.length > 0">
          <stock-changes-list :changes="stockChanges" :showItem="false" />
        </div>
        <b-modal ref="image" hide-footer no-fade centered :title="realItem && realItem.name">
          <div v-if="loading === true" class="justify-content-center" style="display: flex">
            <b-spinner class="center" label="Loading..."></b-spinner>
          </div>
          <img v-if="imageId" style="width: 100%; height: 80dvh; object-fit: contain;" :src="baseURL + imageId" v-on:load="loading = false" />
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../http-common";
import GenericInputComponent from "./components/GenericInputComponent";
import EditPositionComponent from "./components/EditPositionComponent";
import EditBarcodeComponent from "./components/EditBarcodeComponent";
import EditContentComponent from "./components/EditContentComponent";
import EditItemGroupComponent from "./components/EditItemGroupComponent";
import EditTextareaComponent from "./components/EditTextareaComponent";
import EditUrlComponent from "./components/EditUrlComponent";
import EditPercentComponent from "./components/EditPercentComponent";

import StockChangesList from "./StockChangesList";

export default {
  name: "item",
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  components: {
    GenericInputComponent,
    EditPositionComponent,
    EditBarcodeComponent,
    EditContentComponent,
    EditItemGroupComponent,
    EditTextareaComponent,
    EditUrlComponent,
    EditPercentComponent,
    StockChangesList,
  },
  data() {
    return {
      realItem: null,
      imageId: null,
      loading: false,
      stock: undefined,
      stockChanges: null,
    };
  },
  methods: {
    /* eslint-disable no-console */
    openImage() {
      this.loading = true;
      http
        .get("/image/" + this.realItem.imageId)
        .then((response) => {
          this.imageId = response.data.original;
        })
        .catch((e) => {
          console.log(e);
        });
      this.$refs.image.show();
    },
    retrieveItem() {
      http
        .get("/item/" + this.$route.params.itemId)
        .then((response) => {
          this.realItem = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveStock() {
      const itemId = this.item === null ? this.$route.params.itemId : this.item.id;
      http
        .get("/item/" + itemId + "/stock")
        .then((response) => {
          this.stock = response.data;
          console.log(this.stock);
        })
        .catch(console.error);
    },
    retrieveStockChanges() {
      const itemId = this.item === null ? this.$route.params.itemId : this.item.id;
      http
        .get("/item/" + itemId + "/stockChanges")
        .then((response) => {
          this.stockChanges = response.data;
        })
        .catch(console.error);
    },
  },
  created() {
    this.baseURL = http.defaults.baseURL + "/file/";
  },
  mounted() {
    if (this.item === null) {
      this.retrieveItem();
    } else {
      this.realItem = this.item;
    }
    this.retrieveStock();
    this.retrieveStockChanges();
  },
  /* eslint-enable no-console */
};
</script>

<style>
.btn-sm-flat {
  padding-top: 0 !important;
  padding-bottom: 0px !important;
}
</style>

import Vue from "vue";
import App from "./App.vue";
import router from './router';

import axios from './http-common';
import VueCollapse from 'vue2-collapse';
import Roles from './roles';

Vue.use(VueCollapse);
import { printDate, printDateTime, printDayDateTime } from './dateFilters';
Vue.filter('asDate', printDate);
Vue.filter('asDateTime', printDateTime);
Vue.filter('asDayDateTime', printDayDateTime);
Vue.filter('asEuro', n => n === null ? "" : n.toLocaleString("de-DE", { maximumFractionDigits: 2 }) + " €");

// see https://www.npmjs.com/package/@fortawesome/vue-fontawesome#recommended
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt, faPlay, faPause, faStop, faRedo, faStepForward, faImage, faLink, faPlusSquare, faEdit, faUnlink, faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faTrashAlt, faPlay, faPause, faStop, faRedo, faStepForward, faImage, faLink, faPlusSquare, faEdit, faUnlink, faTimes, faSave);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// see https://bootstrap-vue.js.org/docs/#using-module-bundlers
import { NavPlugin, TabsPlugin, CardPlugin, AlertPlugin, ModalPlugin, SpinnerPlugin, FormDatepickerPlugin, ToastPlugin, FormFilePlugin, FormCheckboxPlugin} from 'bootstrap-vue';
Vue.use(NavPlugin);
Vue.use(TabsPlugin);
Vue.use(CardPlugin);
Vue.use(AlertPlugin);
Vue.use(ModalPlugin);
Vue.use(SpinnerPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(ToastPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormCheckboxPlugin);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

axios.interceptors.response.use(undefined, function(error) {
    if (error.response !== undefined && error.response.status === 401) {
        Roles.setUser(null);
        router.push({ name: 'login' }).catch(console.error);
    }
    return Promise.reject(error);
});

new Vue({
    router,
    render: h => h(App),
    performance: true
}).$mount("#app");
import { render, staticRenderFns } from "./ScannedItemPage.vue?vue&type=template&id=a8b2eace"
import script from "./ScannedItemPage.vue?vue&type=script&lang=js"
export * from "./ScannedItemPage.vue?vue&type=script&lang=js"
import style0 from "./ScannedItemPage.vue?vue&type=style&index=0&id=a8b2eace&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports